<template>
  <div class="country-sidebar">
    <SfSidebar
      class="country-sidebar__sidebar"
      :visible="isCountrySidebarOpen"
      position="right"
      :persistent="true"
      @close="handleCloseSidebar"
    >
      <template #bar> <span /> </template>
      <template #circle-icon> <span /> </template>
      <template name="content-top">
        <div class="country-sidebar__sidebar__title">
          {{ $t('Choose your country') }}
          <CrossIcon
            class="country-sidebar__sidebar__title__icon"
            @click="handleCloseSidebar"
          />
        </div>
      </template>
      <div class="country-sidebar__sidebar__subtitle">
        {{
          $t('Catalog and services may vary depending on the selected country.')
        }}
      </div>
      <VueSelect
        class="country-sidebar__country-select"
        v-if="Array.isArray(mappedMarkets) && mappedMarkets.length > 0"
        :options="mappedMarkets"
        v-model="selectedMarket"
      >
        <template #header>
          <span class="country-sidebar__country-select__title">
            {{ $t('Choose your country') }}
          </span>
        </template>
        <template #selected-option="{ countryIso, label }">
          <div class="country-sidebar__country-select__selected-option">
            <CountryFlag :country="countryIso" />
            <span>{{ label }}</span>
          </div>
        </template>
        <template #option="option">
          <div class="country-sidebar__country-select__option">
            <CountryFlag :country="option.countryIso" />
            <span>{{ option.label }}</span>
          </div>
        </template>
        <template #open-indicator>
          <ChevronBlackSmallDownIcon />
        </template>
      </VueSelect>
      <VueSelect
        :options="availableLanguages"
        class="country-sidebar__country-select"
        v-model="selectedLanguage"
        :disabled="availableLanguages.length < 2"
      >
        <template #header>
          <span class="country-sidebar__country-select__title">
            {{ $t('Choose your language') }}
          </span>
        </template>
        <template #selected-option="{ label }">
          <div class="country-sidebar__country-select__selected-option">
            <CountryFlag :country="label === 'English' ? 'gb' : 'it'" />
            <span>{{ label }}</span>
          </div>
        </template>
        <template #open-indicator>
          <ChevronBlackSmallDownIcon />
        </template>
      </VueSelect>
      <a
        :href="confirmUrl"
        class="change-locale-button"
        :class="!confirmUrl && 'change-locale-button__disabled'"
      >
        {{ $t('Confirm') }}
      </a>
    </SfSidebar>
  </div>
</template>

<script>
import {
  defineComponent,
  watch,
  ref,
  useRoute,
  onMounted,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import { useUiState, useWindow, useGeminiApi } from '~/composables';
import {
  CrossIcon,
  ChevronBlackSmallDownIcon,
} from '~/components/General/Icons';
import { useI18n } from '~/helpers/hooks/usei18n';
import { VueSelect } from 'vue-select';
import CountryFlag from 'vue-country-flag';
import { marketToLocaleMapper } from '~/helpers/util';
import { isCmsPage, getOtherLocale } from '~/helpers/cms/cmsData';

export default defineComponent({
  name: 'CountrySidebar',
  components: {
    SfSidebar,
    CrossIcon,
    VueSelect,
    CountryFlag,
    ChevronBlackSmallDownIcon,
  },
  setup() {
    const { isCountrySidebarOpen, toggleCountrySidebar } = useUiState();
    const { handleBodyClass } = useWindow();
    const mappedMarkets = ref([]);
    const selectedMarket = ref({
      value: 'en',
      label: 'Italy',
      countryIso: 'IT',
    });
    const trans = useI18n();
    const route = useRoute();
    const { geminiData } = useGeminiApi();
    const {
      $vsf,
      app: { i18n },
    } = useContext();
    const { customRoutes } = $vsf.$gemini.config;
    const selectedLocale = computed(
      () => route?.value.fullPath?.split('/')[1] ?? 'en'
    );
    const language = computed(() =>
      selectedLocale.value === 'it' ? 'it' : 'en'
    );

    const availableLanguages = computed(() =>
      ['it', 'en', '/it/', '/en/'].includes(selectedMarket.value.value)
        ? [
            { label: trans.t('Italiano'), value: trans.t('Italiano') },
            { label: trans.t('English'), value: trans.t('English') },
          ]
        : [{ label: trans.t('English'), value: trans.t('English') }]
    );
    const selectedLanguage = ref([
      { label: trans.t('English'), value: trans.t('English') },
    ]);
    const marketCode = computed(() =>
      (['it', 'en'].includes(selectedMarket.value.value)
        ? selectedLanguage.value.label === trans.t('Italiano')
          ? 'it'
          : 'en'
        : selectedMarket.value.value
      ).replace(/\//g, '')
    );
    const lockSameLocale = computed(
      () =>
        ((selectedLanguage?.value?.label === 'Italiano' &&
          selectedLocale.value === 'it') ||
          (selectedLanguage?.value?.[0]?.label === 'Italiano' &&
            selectedLocale.value === 'it') ||
          (selectedLanguage?.value?.label === 'English' &&
            selectedLocale.value === 'en') ||
          (selectedLanguage?.value?.[0]?.label === 'English' &&
            selectedLocale.value === 'en')) &&
        ['it', 'en'].includes(marketCode.value)
    );
    const confirmUrl = computed(() => {
      if (
        !selectedLanguage.value ||
        !selectedMarket.value ||
        !marketCode.value ||
        lockSameLocale.value
      )
        return '';
      if (
        selectedLocale?.value === 'it' &&
        (selectedLanguage?.value?.[0]?.label === 'English' ||
          selectedLanguage?.value?.label === 'English')
      ) {
        return '/en';
      }
      if (selectedLocale.value === marketCode.value) {
        return route.value?.fullPath || `/${marketCode.value}`;
      }
      const routePath = route.value?.path || '';
      const routePathPage = routePath?.split('/')?.[2] || '';
      if (!routePathPage || routePathPage === 'checkout') {
        return `/${marketCode.value}`;
      }
      if (routePathPage === 'my-account') {
        const myAccountPage = routePath?.split('/')?.[3]?.split('?')?.[0] || '';
        if (!myAccountPage) return `/${marketCode.value}/my-account`;
        const myAccountMap = {
          panoramica: 'overview',
          overview: 'panoramica',
          ordini: 'order-history',
          'order-history': 'ordini',
          'informazioni-account': 'my-profile',
          'my-profile': 'informazioni-account',
          indirizzi: 'addresses-details',
          'addresses-details': 'indirizzi',
          'newsletter-e-profilazione': 'newsletter-and-profiling',
          'newsletter-and-profiling': 'newsletter-e-profilazione',
        };
        return `/${marketCode.value}/my-account/${
          myAccountMap[myAccountPage] || ''
        }`;
      }
      const updatedUrl = `/${marketCode.value}${
        route.value?.fullPath?.replace(`/${selectedLocale.value}`, '') || ''
      }`;
      if (selectedLocale.value !== 'it' && marketCode.value !== 'it') {
        return updatedUrl;
      }
      if (isCmsPage(routePathPage)) {
        return (
          updatedUrl?.replace(
            `/${routePathPage}`,
            `/${getOtherLocale(routePathPage)}`
          ) || ''
        );
      }
      if (customRoutes?.includes(`/${routePathPage}`)) {
        return updatedUrl;
      }
      const geminiDataKey = `${marketCode.value === 'it' ? 'it' : 'en'}Url`;
      if (geminiData.value?.[geminiDataKey]) {
        return `/${marketCode.value}/${geminiData.value[geminiDataKey]}`;
      }
      return `/${marketCode.value}`;
    });

    const localStorageItemName = 'vsf-country-selected';
    const canUseLocalStorage = !!(process && process.browser && localStorage);

    const stringifiedMarket = computed(() => {
      return ['it', 'en'].includes(selectedMarket.value.value)
        ? JSON.stringify({
            ...selectedMarket.value,
            value: marketCode.value,
          })
        : JSON.stringify(selectedMarket.value);
    });

    const handleSidebarAppearance = () => {
      if (!canUseLocalStorage) return;
      if (!localStorage.getItem(localStorageItemName)) {
        localStorage.setItem(
          localStorageItemName,
          JSON.stringify(selectedMarket.value)
        );
        return;
      }
      selectedMarket.value = JSON.parse(
        localStorage.getItem(localStorageItemName)
      );
      selectedLanguage.value =
        selectedMarket.value.value === 'it'
          ? [{ label: trans.t('Italiano'), value: trans.t('Italiano') }]
          : [{ label: trans.t('English'), value: trans.t('English') }];
    };

    const handleSidebarValues = () => {
      mappedMarkets.value = marketToLocaleMapper(i18n, language.value);
      mappedMarkets.value = [
        ...new Set(
          mappedMarkets.value.sort((a, b) => a.label.localeCompare(b.label))
        ),
      ];
    };

    const handleCloseSidebar = () => {
      if (canUseLocalStorage) {
        localStorage.setItem(
          localStorageItemName,
          JSON.stringify({
            value: 'en',
            label: 'Italy',
            countryIso: 'IT',
          })
        );
      }
      handleBodyClass('removeClass', 'open_menu');
      toggleCountrySidebar();
    };

    watch(
      [selectedMarket, selectedLanguage, availableLanguages],
      (newValues, oldValues) => {
        if (!canUseLocalStorage) return;
        localStorage.setItem(localStorageItemName, stringifiedMarket.value);
        if (
          newValues[2].length === 1 &&
          newValues[2].length !== oldValues[2].length
        )
          selectedLanguage.value = [
            { label: trans.t('English'), value: trans.t('English') },
          ];
      }
    );

    onMounted(() => {
      handleSidebarAppearance();
      handleSidebarValues();
    });

    return {
      isCountrySidebarOpen,
      toggleCountrySidebar,
      mappedMarkets,
      selectedMarket,
      availableLanguages,
      selectedLanguage,
      marketCode,
      selectedLocale,
      handleCloseSidebar,
      lockSameLocale,
      confirmUrl,
      geminiData,
    };
  },
});
</script>

<style lang="scss">
@import 'vue-select/dist/vue-select.css';
.country-sidebar {
  .sf-sidebar {
    &__content {
      padding: 1.875rem;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      .change-locale-button {
        @include font-14x16;
        &:hover {
          cursor: pointer;
        }
        text-transform: uppercase;

        @include flex-center;
        font-weight: var(--font-weight-medium);
        width: 100%;
        height: var(--button-height);
        border-radius: var(--border-radius);
        background-color: var(--c-black);
        color: var(--c-white);
        border: none;
        &:hover {
          opacity: 0.8;
        }
        &__disabled {
          background: var(--c-grey);
          pointer-events: none;
          opacity: 1;
        }
      }
    }
    &__sidebar__title__icon {
      @include pointer;
    }
  }
  &__country-select {
    width: 100%;
    @include pointer;
    .vs__dropdown-toggle {
      height: 3.125rem;
      border: var(--black-border);
    }
    .vs__selected {
      margin: 0;
      padding: 0;
      height: 3.125rem;
    }
    &__title {
      @include font-14x16;
    }
    &__option,
    &__selected-option {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }
    &__selected-option {
      height: 30px;
    }
    &.vs--disabled {
      .vs__search {
        display: none;
      }
      .vs__dropdown-toggle {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
  &__sidebar {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include font-16x16;
    }
    &__subtitle {
      @include font-14x19;
    }
  }
  .normal-flag {
    margin: 0 !important;
  }
  input {
    @include pointer;
    caret-color: transparent;
  }
}
</style>
